.App {
  text-align: center;
  display: inline-block;
  left: 50%;
}

#innerDiv1 {
  background-color: white;
  width: 50%;
  margin-left: 25%;
  min-height: 100%;
  padding-left: 11.5%;
  padding-top: 5%;
}

#heading-main {
  font-size: 3.5rem;
  padding-bottom: 19px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  width: 72.5%;
}


.loading-indicator{
  margin-top:calc(100vh/2 - 50%);
  font-weight: bold;
  font-size: 30px;
}