.file-uploader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-content: space-between;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 20px;
}
.file-uploader .doc_type--label {
  margin-right: 5px;
  font-weight: bold;
  font-size: 23px;
}
.file-uploader .choose-file-Btn {
  height: 40px;
  color: white;
  justify-content: center;
  outline: none;
  border-radius: 5px;
  background-color: #17a2b8;
  border: 1px solid #ccc;
  padding: 0.3rem 0.6rem;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 600px) {
  .file-uploader .doc_type--label {
    font-size: 18px;
  }
  .file-uploader .choose-file-Btn {
    font-size: 18px;
  }
}
