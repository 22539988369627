* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins", sans-serif;
}

body {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: rgb(246, 248, 250);
}

.container {
  max-width: 700px;
  width: 100%;
  background: rgb(199, 226, 238);
  padding: 25px 30px;
  border-radius: 5px;
}

.container .title {
  font-size: 45px;
  font-weight: bold;
  font-weight: 800;
  position: relative;
  white-space: nowrap;
}
.container form .user-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
form .user-details .input-box {
  margin-bottom: 15px;
  width: calc(100% - 10px);
}

.user-details .input-box input {
  width: 100%;
  height: 65px;
  margin-bottom: 10px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  transition: all 0.3s ease;
}
.user-details .input-box input:focus,
.user-details .input-box input:valid {
  border-color: #2380be;
}

form .select-files .choose-files {
  flex-direction: row;
  margin: 10px 0;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-end;
  align-items: baseline;
}
.files-to-upload {
  display: flex;
  flex-direction: row;
  max-height: 50px;
  margin-bottom: 5px;
  background: white;
  border-radius: 5px;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}
.files-to-upload .file-name {
  padding: 10px 10px 10px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 50%;
}
.files-to-upload .file-progress-bar {
  padding: 16px 10px 20px 10px;
}
.circle-icon {
  font-size: 30px;
  margin: 10px 10px 10px 20px;
  color: #17a2b8;
}
.button {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: flex-end;
  align-items: flex-end;
}
.upload-files {
  height: 50px;
  width: 150px;
  color: white;
  margin-left: 45%;
  margin-top: 60px;
  outline: none;
  border-radius: 5px;
  background-color: #17a2b8;
  border: 1px solid #ccc;
  font-weight: bold;
  font-size: 30px;
  transition: all 0.3s ease;
}

*:disabled {
  user-select: none;
  pointer-events: none;
  cursor: not-allowed !important;
  color: grey !important;
  opacity: 0.6 !important;
}

@media only screen and (max-width: 600px) {
  .container .title {
    font-size: 30px;
  }
  .upload-files {
    font-size: 25px;
  }
}
